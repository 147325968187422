
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { TableData, ResourcesLocation, DisposeInfo } from '@/types/dispatch'
import { FileInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { MapLocation, UploadFile }
})

export default class DispatchDispose extends Vue {
  private infoData: TableData | any = {}

  private resourcesLocationList: ResourcesLocation[] = []
  private info: DisposeInfo = {
    scenariosId: '',
    content: '',
    dispatchState: '',
    resourceList: []
  }

  private submitShow = false

  private rules = {
    scenariosId: [
      { required: true, message: '请选择预案', trigger: 'change' }
    ],
    dispatchState: [
      { required: true, message: '请选择处置进度', trigger: 'change' }
    ]
  }

  private disposeList = []
  private scenariosDetail = {}

  get dispatchId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.dispatch.selectDispatchByDispatchId, {
      dispatchId: this.dispatchId
    }).then(res => {
      if (res) {
        this.infoData = res
        this.resourcesLocationList = [{
          amount: 1,
          longitude: res.longitude,
          latitude: res.latitude
        }]
        res.scenariosId && this.getScenariosDetail(res.scenariosId)
      }
    })
  }

  // 预案匹配
  getScenariosDetail (scenariosId: string) {
    this.$axios.get(this.$apis.scenarios.selectDispatchScenariosByScenariosId, {
      scenariosId: scenariosId
    }).then(res => {
      this.scenariosDetail = res || {}
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.dispatch.insertDispatchHandle, {
          ...this.info,
          dispatchId: this.dispatchId
        }).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'dispatchList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
